<script>
import Layout from "../../layouts/main";
import Breadcrumb from '../../../components/breadcrumb.vue';
import * as moment from "moment";
import { required, between } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

import {
  FETCH_TRANSACTION_DETAILS,
  FETCH_REFUND_DATA_FOR_TRANSACTION,
  CREATE_REFUND_FOR_TRANSACTION,
  UPDATE_REFUND_STATUS
} from "@/state/actions.type";
import CloseModalBtn from "../../../components/close-modal-btn.vue";

export default {
  data() {
    return {
      orderRef: "",
      refundData: {},
      form: {
        amount: "",
        refund_reason: "",
        description: "",
        channel: "",
        refund_type: ""
      },
      isCreatingRefund: false,
      refundId: "",
      refundDetailFields: [
        { key: "refund_date", label: "views.payments.refund.table.refund_date" },
        { key: "refund_id", label: "views.payments.refund.table.refund_id" },
        { key: "psp_refund_id", label: "views.payments.refund.table.psp_refund_id" },
        { key: "amount", label: "views.payments.refund.table.amount" },
        { key: "currency", label: "views.payments.refund.table.currency" },
        { key: "refund_status", label: "views.payments.refund.table.refund_status" },
        { key: "refund_type", label: "views.payments.refund.table.refund_type" },
        { key: "refund_reason", label: "views.payments.refund.table.refund_reason" },
        { key: "description", label: "Note" },
      ],
      refundReasons: [
        { text: 'Duplicate', value: 'duplicate' },
        { text: 'Fraudulent', value: 'fraudulent' },
        { text: 'Requested By Customer', value: 'customer_request' },
        { text: 'Other', value: 'other' }
      ],
      refundReasonsObj: {
        duplicate: 'Duplicate',
        fraudulent: 'Fraudulent',
        customer_request: 'Requested By Customer',
        other: 'Other'
      }
    };
  },
  validations() {
    return {
        form: {
          amount: {
            required,
            between: between(0.01, this.refundData.amountToRefund)
          },
          refund_reason: {
            required
          },
          description: {}
        }
    }
  },
  components: {
    Layout,
    Breadcrumb,
    CloseModalBtn
},
  filters: {
    date(date) {
      return moment(date).format("MMM Do YYYY, h:mm:ss a");
    },
    dateFormat(value) {
      return moment(value).format("DD MMM YYYY");
    },
    time(value) {
      return 'at ' + moment(value).format("hh:mm A");
    },
  },
  created() {
    this.orderRef = this.$route.query.order_ref;
    this.$store
      .dispatch(`transactions/${FETCH_TRANSACTION_DETAILS}`, {
        order_ref: this.orderRef,
        submerchant_key: this.$route.query.key
      });

    this.fetchRefundData();
  },
  computed: {
    items() {
      return [
        {
          text: this.$t("common.transactions"),
          to: { name: 'Transactions' },
        },
        {
          text: this.$t("views.payments.transactions.title"),
          to: { name: 'Transaction Details', query: { order_ref: this.orderRef } },
        },
        {
          text: this.$t("views.payments.refund.refund_details"),
          active: true,
        },
      ];
    },

    transactionData() {
      return this.$store.state.transactions.transactionData;
    },

    title() {
      return this.$t("views.payments.refund.title");
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    fetchRefundData() {
        this.$store
        .dispatch(
            `transactions/${FETCH_REFUND_DATA_FOR_TRANSACTION}`,
            this.orderRef
        )
        .then((response) => {
            this.refundData = response;
            this.form.amount = this.refundData ? this.refundData.amountToRefund : 0;

            if(this.transactionData.channel_key === 'SMARTPAY') {
              const isInQueueStatus = this.refundData.refundDetails.find(detail => detail.refund_status === 'IN_QUEUE');
              this.showAction(Boolean(isInQueueStatus));
            }
        });
    },

    showAction(show) {
      if(show) {
        this.refundDetailFields.push({ key: "action", label: "Action", stickyColumn: true });
      } else {
        this.refundDetailFields = this.refundDetailFields.filter(field => field.key !== 'action');
      }
    },

    createRefund() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      this.form.channel = this.transactionData.channel_name;

      this.isCreatingRefund = true;

      if(this.form.amount === this.transactionData.amount) {
        this.form.refund_type = 'full';
      } else {
        this.form.refund_type = 'partial';
      }
      this.$store
        .dispatch(`transactions/${CREATE_REFUND_FOR_TRANSACTION}`, {
          order_ref: this.orderRef,
          refundData: this.form
        })
        // eslint-disable-next-line no-unused-vars
        .then((result) => {
            console.log(result);
            this.$bvModal.hide('refund-modal');
            Swal.fire({
              title: `<strong class="text-success">${this.$t("views.payments.refund.refund_success")}</strong>`,
              icon: 'success',
              html:
                `
                  <p>${this.$t("common.processing")}</p>
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <p>${this.$t("views.payments.refund.transaction_id")}</p>
                          <p>${this.$t("views.payments.refund.refund_amount")}</p>
                        </div>
                        <div class="col-md-6">
                          <p>${this.transactionData.merchant_order_ref}</p>
                          <p>${this.form.amount}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                `,
              focusConfirm: true,
              confirmButtonText: 'Done'
            })
        })
        .catch((error) => {
            console.log(error);
            this.$notify({
                type: 'error',
                text: error.Message.error,
                closeOnClick: true,
            });
        })
        .finally(() => {
            this.fetchRefundData();
            this.isCreatingRefund = false;
        });
    },

    resetRefundModal() {
      this.form.refund_reason = 'duplicate';
      this.form.amount = this.refundData.amountToRefund;
      this.form.description = '';
      this.form.channel = '';

      this.$nextTick(() => {
          this.$v.$reset();
      });
    },
    updateStatus(refundId) {
      this.refundId = refundId;
      this.$refs['modal-update-status'].show();
    },
    approve(status) {
      this.$store.dispatch(`transactions/${UPDATE_REFUND_STATUS}`, {
        status: status,
        refundId: this.refundId
      })
      .then((response) => {
        this.$notify({
          type: 'success',
          text: response.message,
          closeOnClick: true,
        });
        this.fetchRefundData();
      })
      .catch((error) => {
        const err = JSON.parse(error.message);
        this.$notify({
            type: 'error',
            text: err.message,
            closeOnClick: true,
        });
      })
    },
    hideUpdateStatusModal() {
      this.$refs['modal-update-status'].hide();
    },
    transactionAmount() {
      if(this.transactionData.country_code) {
        return new Intl.NumberFormat(this.transactionData.country_code, { style: 'currency', currency: this.transactionData.currency }).format(this.transactionData.amount)
      }
      return this.transactionData.amount;
    },
  },
};
</script>

<template>
  <Layout>
    <div class="d-flex">
      <breadcrumb :items="items" :goBackTo="{ name: 'Transaction Details', query: { order_ref: this.orderRef } }" />
      <b-btn class="float-right px-3 ml-auto refund-button" variant="primary" :disabled="!refundData || !(refundData.amountToRefund > 0)" v-b-modal.refund-modal><img src="@/assets/images/refund.svg" alt="refund" class="mr-1 mb-1" />{{ $t("views.payments.refund.create") }}</b-btn>
    </div>
    <div class="my-4">
      <p class="mb-0 p-3 collapse-header common-bg-30">
        Payment Details
      </p>
      <div class="mt-2">
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Transaction ID</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.merchant_order_ref }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Transaction Date</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.created_at | date }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Customer Name</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.buyer_name || '-' }}<span v-if="transactionData.buyer_email">( <a class="chai-text font-16px" :href="`mailto:${transactionData.buyer_email}`">{{ transactionData.buyer_email }}</a> )</span></p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Amount</p>
          <p class="col-6 collapse-body-table-col">{{ transactionAmount() }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Payment Method</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.method_name }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Payment Channel</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.channel_name }}</p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Merchant Email</p>
          <p class="col-6 collapse-body-table-col"><a v-if="transactionData.merchant_email_address" class="chai-text font-16px" :href="`mailto:${transactionData.merchant_email_address}`">{{ transactionData.merchant_email_address }}</a></p>
        </div>
        <div class="row collapse-body-table-row">
          <p class="col-6 collapse-body-table-head-col">Transaction Status</p>
          <p class="col-6 collapse-body-table-col">{{ transactionData.status_code }}</p>
        </div>
      </div>
    </div>

    <b-alert
      variant="danger"
      :show="!transactionData.is_multi_refund_allowed"
    >
      {{ $t("views.payments.refund.multiple_refund_note") }}
    </b-alert>

    <b-table
      class="table-custom text-center table-centered"
      :items="refundData.refundDetails"
      :fields="refundDetailFields"
      responsive="sm"
      :small="true"
      sticky-header="500px"
      show-empty
      :empty-text="$t('common.no-records')"
      busy.sync="true"
      hover
    >
      <template v-slot:head()="data">
        {{ $t(data.label) }}
      </template>
      <template v-slot:cell(refund_date)="row">
        <p class="table-date">{{ row.value | dateFormat }}</p>
        <p class="table-time">{{ row.value | time }}</p>
      </template>
      <template v-slot:cell(refund_status)="row">
        <div
          class="badge font-size-12"
          :class="{
            'badge-soft-danger': `${row.value}` === 'FAIL',
            'badge-soft-success': `${row.value}` === 'SUCCESS',
            'badge-soft-warning': `${row.value}` === 'IN_QUEUE',
          }"
        >
          {{ row.value }}
        </div>
      </template>
      <template v-slot:cell(refund_reason)="{value}">
        {{ refundReasonsObj[value] }}
      </template>

      <template v-slot:cell(amount)="row">
        <div>
          {{
            row.value.toLocaleString("en-US", {
              style: "currency",
              currency: row.item.currency,
              maximumFractionDigits: 5
            })
          }}
        </div>
      </template>
      <template v-slot:cell(action)="row">
        <b-btn
          size="sm"
          variant="light"
          v-if="row.item.refund_status === 'IN_QUEUE'"
          @click="updateStatus(row.item.refund_id)"
        >
          Edit
        </b-btn>
      </template>
    </b-table>
    <div class="row my-4">
      <div class="col-lg-12">
        <!-- <b-card
          :header="$t('views.payments.refund.refund_details')"
          header-tag="header"
          class="border"
          header-text-variant="dark"
        >
          <div
            class="table-responsive text-center table-custom"
            style="padding-top: 1rem"
          >
            
          </div>
        </b-card> -->
        <b-modal id="refund-modal" body-class="p-0" title="Create Refund" centered @show="resetRefundModal" @hidden="resetRefundModal" hide-footer no-close-on-backdrop>
          <template #modal-header="{ close }">
            <!-- Emulate built in modal header close button action -->
            <h5 class="font-20px text-black">Create Refund</h5>
            <img @click="close" style="cursor: pointer;" src="@/assets/images/close.svg" alt="close" />
          </template>

          <div class="d-flex justify-content-between p-3 common-bg-30" style="border-bottom: 1px solid #DCDAD5;">
            <p class="mb-0 collapse-body-table-head-col">Transaction ID</p>
            <p class="mb-0 collapse-body-table-col">{{ transactionData.merchant_order_ref }}</p>
          </div>
          <div class="d-flex justify-content-between p-3 common-bg-30">
            <p class="mb-0 collapse-body-table-head-col">Customer Email</p>
            <p class="mb-0 collapse-body-table-col">{{ transactionData.buyer_email }}</p>
          </div>

          <form @submit.prevent class="p-3">
            <b-form-group :label="$t('common.amount')" label-class="collapse-body-table-col">
              <b-form-input
                name="amount"
                type="number"
                v-model.number="$v.form.amount.$model"
                :state="validateState('amount')"
                :max="refundData.amountToRefund"
                style="padding: 18px 25px !important; height: 44px;"
              ></b-form-input>
              <div v-if="refundData && refundData.forex_details && transactionData && transactionData.cross_border_data && transactionData.cross_border_data.forex_details" class="conversion-rates mt-2">
                <span v-if="transactionData.cross_border_data.forex_details.invoice_currency === transactionData.currency">
                  {{ form.amount ? (form.amount) : 0 }} {{ transactionData.cross_border_data.forex_details.invoice_currency }} <img width="12px" height="12px" src="@/assets/images/repeat.jpg" alt="converts" /> {{ ((form.amount ? form.amount : 0)*refundData.forex_details.forex_rate).toFixed(3) }} {{ transactionData.cross_border_data.forex_details.payment_currency }} 
                </span>
                <span v-else>
                  {{ form.amount ? (form.amount) : 0 }} {{ transactionData.cross_border_data.forex_details.payment_currency }} <img width="12px" height="12px" src="@/assets/images/repeat.jpg" alt="converts" /> {{ ((form.amount ? form.amount : 0)/refundData.forex_details.forex_rate).toFixed(3) }} {{ transactionData.cross_border_data.forex_details.invoice_currency }} 
                </span>
                <span>
                  1 {{ transactionData.cross_border_data.forex_details.invoice_currency }} = {{ refundData.forex_details.forex_rate.toFixed(6) }} {{ transactionData.cross_border_data.forex_details.payment_currency }}
                </span>
              </div>
              <div class="invalid-feedback" v-if="!$v.form.amount.between">{{ $t("views.payments.refund.validation.amount") }} {{$v.form.amount.$params.between.min}} {{ $t("views.payments.refund.validation.and") }} {{$v.form.amount.$params.between.max}}</div>
            </b-form-group>

            <b-form-group :label="$t('views.payments.refund.refund_reason')" label-class="collapse-body-table-col">

              <b-dropdown variant="white" dropup menu-class="w-100" class="w-100">
                <template v-slot:button-content>
                  {{ refundReasonsObj[form.refund_reason] || "" }}
                  <img class="mr-0" src="@/assets/images/down.svg" alt="down" height="18" width="18" />
                </template>

                <b-dropdown-item
                  v-for="(option, i) in refundReasons"
                  :key="`refundReason${i}`"
                  @click="() => form.refund_reason = option.value"
                  :link-class="{'active': option.value === form.refund_reason, 'd-flex': true}"
                >
                  <div class="my-auto">
                    {{ option.text }}
                  </div>
                </b-dropdown-item>
              </b-dropdown>

              <!-- <b-form-select v-model="$v.form.refund_reason.$model" :state="validateState('refund_reason')" class="custom-dropdown-select w-100" style="padding-top: 0.7rem; padding-bottom: 0.7rem;">
                <b-form-select-option v-for="(reason, index) in refundReasons" :key="index" :value="reason.value">{{ reason.text }}</b-form-select-option>
              </b-form-select>
              <div class="error" v-if="!$v.form.refund_reason.required">{{ $t("views.payments.refund.validation.refund_reason") }}</div> -->
            </b-form-group>

            <b-form-group :label="$t('views.payments.refund.note')" label-class="collapse-body-table-col">
              <b-form-textarea
                id="note"
                v-model="$v.form.description.$model"
                :placeholder="$t('common.type_here')"
                :state="validateState('description')"
                rows="3"
                max-rows="6"
                class="textarea-input"
              ></b-form-textarea>
            </b-form-group>

            <div style="gap: 12px;" class="d-flex justify-content-end">
              <b-button variant="white" size="sm" class="cancel" style="color: #000;" @click="$bvModal.hide('refund-modal')">{{ $t("button.cancel") }}</b-button>
              <b-button variant="white" size="sm" class="save" @click="createRefund" :disabled="isCreatingRefund">{{ $t("button.save") }}</b-button>
            </div>
          </form>
        </b-modal>

        <b-modal
          ref="modal-update-status"
          ok-title="Success"
          cancel-title="Failed"
          ok-variant="success-approve px-3"
          cancel-variant="primary px-3"
          footer-bg-variant="light"
          footer-class="justify-content-center"
          size="md"
          @ok="approve(true)"
          @cancel="approve(false)"
          no-close-on-backdrop
          hide-header
          centered
          v-if="(transactionData.channel_key === 'SMARTPAY') && !isMasterMerchant"
        >
          <span @click="hideUpdateStatusModal"><CloseModalBtn /></span>
          <div class="d-flex justify-content-center mx-auto my-3 text-danger">
            Please use the respective PSP Dashboard to verify and update the correct status of this refund.
          </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.refund-button {
  background: #FC6B2D;
  border-color: #FC6B2D;
}
.collapse-header {
  border-radius: 15px 15px 0px 0px;
  border-bottom: 1px solid #DCDAD5;
  font-size: 20px;
}
.collapse-body-table-row {
  border-bottom: 1px solid #DCDAD5;
  margin-right: unset;
  margin-left: unset;
  height: 60px;
}
.collapse-body-table-head-col {
  color: rgba(0, 0, 0, 0.50);
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  margin-top: auto;
  margin-bottom: auto;
}
.collapse-body-table-col {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 350;
  margin-top: auto;
  margin-bottom: auto;
}
.font-20px {
  font-size: 20px;
}
.form-control {
  border-radius: 12px;
  border: 1px solid #DCDAD5;
}
.form-control.is-invalid {
  border-color: #ff3d60 !important;
}
.form-control.is-valid {
  border-color: #1cbb8c;
}
.textarea-input {
  border-radius: 12px;
  border: 1px solid #DCDAD5;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 18px 25px;
}
.conversion-rates{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

::v-deep .dropdown-toggle {
  display: flex;
  height: 44px;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  border: 1px solid #DCDAD5;
}
::v-deep .dropdown-menu {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
  max-height: 50vh;
  overflow-y: scroll;
}

::v-deep a {
  height: 44px;
  .active {
    border-right: 5px solid #FC6B2D;
    background: rgba(220, 218, 213, 0.30);
  }
}
</style>